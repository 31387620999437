.skills-container {
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  opacity: 0; /* Start hidden */
  transform: translateY(50px); /* Start off-screen */
  transition: opacity 1.5s ease-out, transform 1.5s ease-out; /* Smooth transition */
  width: 100%; /* Ensure full width of grid cell */
  margin-right: 0; /* Remove margin to avoid spacing issues */
}

.skills-container.visible {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Slide up */
}

.skills-container h2 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  text-align: center;
}
