.tech-icons-container {
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  opacity: 0; /* Start hidden */
  transform: translateY(50px); /* Start off-screen */
  transition: opacity 1.5s ease-out, transform 1.5s ease-out; /* Smooth transition */
  width: 100%; /* Ensure full width of grid cell */
  margin-right: 0; /* Remove margin to avoid spacing issues */
  margin-bottom: 2rem;
  text-align: center;
}

.tech-icons-container h1 {
  align-items: center;
  margin-bottom: 1rem;
}

.tech-icons-container.visible {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Slide up */
}

.tech-icons {
  display: flex;
  flex-direction: row; /* Stack children horizontally */
  gap: 1rem; /* Space between icons */
  justify-content: center;
}

.tech-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
