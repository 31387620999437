* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  margin-top: 70px; /* Adjust this value to match the height of your navbar */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: rgb(183, 183, 183) 0px 1px 1px 0px; /* Adjusted shadow */
  border: 2px solid rgb(218, 218, 218); /* Temporary border to check visibility */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensures the navbar stays above other content */
}

.logo img {
  height: 50px;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  flex: 1;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 18px;
  position: relative;
  transition: color 0.3s;
}

.nav-links a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  background: #0000009f;
  transition: width 0.3s;
  left: 0;
  bottom: -5px; /* Position the line slightly below the text */
}

.nav-links a:hover::after {
  width: 100%;
}
