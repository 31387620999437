/* Home.css */
.element {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.element.visible {
  opacity: 1;
}

/* Ensure the container has enough height or padding */
.introduction,
.about,
.portfolio,
.contact {
  min-height: 70vh; /* Adjust as needed */
  padding: 2rem;
}
